import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import ScrollOut from "scroll-out"

import ArticleHeaderGrid from "../../../components/athena/articleHeaderGrid"
import ContributorsGrid from "../../../components/athena/contributorsGrid"
import MoreArticlesGrid from "../../../components/athena/moreArticlesGrid"
import AthenaCta from "../../../components/athena/athenaCta"
import Layout, { athenaTheme } from "../../../components/layout"
import PreFooter from "../../../components/athena/preFooter"
import ScrollProgressBar from "../../../components/scrollProgressBar"
import GridCollagePhoto from "../../../components/athena/gridCollagePhoto"
import { getMaxSectionImageWidth } from "./cycle3-shared"

import styles from "./cooperative-mindset.module.scss"
import sharedStyles from "./cycle3-shared.module.scss"

let theme = {
  ...athenaTheme,
  background: styles.background,
  foreground: styles.foreground,
  headerPosition: styles.position,
}

const Article = ({ data }) => {
  const article = data.craft.article
  const seo = article.seomatic
  const athenaCta = article.athenaCta

  // Images
  const {
    headerImage,
    section1Image,
    section2Image,
    section3Image,
    section4Image,
    section5Image,
  } = data.craft

  let maxSectionImageWidth = getMaxSectionImageWidth()

  // Initialize up ScrollOut library
  useEffect(() => {
    let so = ScrollOut({
      threshold: 0.1,
      cssProps: {
        viewportY: true,
      },
    })

    return so.teardown
  }, [])

  return (
    <Layout seo={seo} mode="athena" theme={theme} shyHeader={false}>
      <Helmet>
        <body className={`athena ${styles.body}`} />
      </Helmet>
      <article
        style={{
          backgroundColor: "#828575",
        }}
      >
        <ScrollProgressBar />
        <div className={styles.article}>
          <div className={styles.articleBody}>
            <div className={styles.text}>
              <div className={`${styles.narrow} wrapper`}>
                <div
                  className={`${styles.headerContentBlock} columns is-tablet is-centered`}
                >
                  <ArticleHeaderGrid
                    titleColor="#F5DCC4"
                    issue={article.issue}
                    title={article.title}
                    subtitle={article.articleSubTitle}
                    headerImage={headerImage}
                  />
                </div>
                <div
                  className={`${styles.contentBlock} columns is-tablet is-centered`}
                >
                  <div className="column is-10-tablet">
                    <div className="mb-8 user-content-athena">
                      <p>Cooperation is a mindset.</p>
                      <p>
                        As we move through life, our behaviors and experiences
                        form a lens through which we perceive the world. By
                        altering our behaviors, we can begin to cultivate a
                        cooperative mindset.
                      </p>
                      <p>
                        Cooperation starts with the self, but it creates a
                        compounding ripple effect that stretches far beyond the
                        constraints of competition. Micro behaviors drive macro
                        impact.
                      </p>
                      <p>
                        Even with sights set on a cooperative future,
                        competition looms as an imposing barrier. It&rsquo;s
                        culturally ingrained at each stage of our lives:
                      </p>
                    </div>
                    <div className="mb-8" style={{ color: "#F5DCC4" }}>
                      <ul>
                        <li className="a-h-3 mb-2">
                          &ldquo;Who won the game?&rdquo;
                        </li>
                        <li className="a-h-3 mb-2">
                          &ldquo;What grade did you get?&rdquo;
                        </li>
                        <li className="a-h-3 mb-2">
                          &ldquo;Why aren&rsquo;t you married yet?&rdquo;
                        </li>
                        <li className="a-h-3 mb-2">
                          &ldquo;How&rsquo;s the 401K looking?&rdquo;
                        </li>
                      </ul>
                    </div>
                    <div className="mb-8 user-content-athena">
                      <p>
                        Recent discourse around the concept of &lsquo;survival
                        of the fittest&rsquo; shows that we&rsquo;ve interpreted
                        Darwin wrong. Survival isn&rsquo;t simply an outcome of
                        strength and brute force as we've been told, but rather
                        harnessing the social and cooperative abilities that
                        make us uniquely human. We sit atop the food chain not
                        because we&rsquo;re the strongest, but because we can
                        work together. Our communication and creative problem
                        solving enable us to survive.
                      </p>
                      <h2 style={{ color: "#F5DCC4" }}>
                        So, how do we start living more cooperatively?
                      </h2>
                      <p>
                        We can begin by incorporating specific behaviors into
                        our everyday lives. Combining the influence of{" "}
                        <a
                          href="https://www.stitcher.com/show/icardinalapps-academy/episode/human-cooperation-audio-48490480"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          David Rand&rsquo;s
                        </a>{" "}
                        behavioral science work with our own experience creating
                        a workplace rooted in cooperation, here are five guiding
                        principles that can change the way we work together:
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={styles.twoColumn}
              style={{ backgroundColor: "#80642E" }}
            >
              <div>
                <div className={sharedStyles.stickyPhotoWrapper}>
                  <GridCollagePhoto
                    src={section1Image.url}
                    width={section1Image.width}
                    height={section1Image.height}
                    maxWidth={maxSectionImageWidth}
                    imgix={true}
                    cols={6}
                    rows={8}
                    altText={section1Image.altText}
                  />
                </div>
              </div>
              <div className={styles.rightColumn}>
                <h2
                  className={styles.contentHeader}
                  style={{ color: "#EEE0CE" }}
                >
                  Cooperation begets cooperation.
                </h2>
                <p className="a-h-4 italic mb-4" style={{ color: "#EEE0CE" }}>
                  Give a little extra.
                </p>
                <div className="user-content-athena">
                  <p>
                    We replicate what we see. Repeated cooperative behaviors set
                    a standard. Selfish behaviors do the same. To foster a
                    cooperative spirit at Zeus Jones, we promote the principle
                    of &ldquo;give a little extra.&rdquo; What might
                    others&rsquo; needs be? How can we proactively anticipate
                    and deliver on them even when it&rsquo;s not expected? Put
                    others ahead, and you'll find yourself ahead in return.
                  </p>

                  <p>
                    A large-scale example: Tesla decided to forgo patents and
                    open-source their technology in 2014. Stated plainly, yet
                    profoundly on the{" "}
                    <a
                      href="https://www.tesla.com/blog/all-our-patent-are-belong-you"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      company&rsquo;s blog
                    </a>
                    : &ldquo;Tesla Motors was created to accelerate the advent
                    of sustainable transport. If we clear a path to the creation
                    of compelling electric vehicles, but then lay intellectual
                    property landmines behind us to inhibit others, we are
                    acting in a manner contrary to that goal.&rdquo;
                  </p>
                </div>
              </div>
            </div>
            <div
              className={styles.twoColumn}
              style={{ backgroundColor: "#353924" }}
            >
              <div>
                <div className={sharedStyles.stickyPhotoWrapper}>
                  <GridCollagePhoto
                    src={section2Image.url}
                    width={section2Image.width}
                    height={section2Image.height}
                    maxWidth={maxSectionImageWidth}
                    imgix={true}
                    cols={8}
                    rows={10}
                    altText={section2Image.altText}
                  />
                </div>
              </div>
              <div className={styles.rightColumn}>
                <h2
                  className={styles.contentHeader}
                  style={{ color: "#C4BCAD" }}
                >
                  Create fluid networks.
                </h2>
                <p className="a-h-4 italic mb-4" style={{ color: "#C4BCAD" }}>
                  Spark more interactions.
                </p>
                <div className="user-content-athena">
                  <p>
                    The more interconnected we are, the more interactions we can
                    have. Straightforward enough, but consider that by creating
                    more interactions, we put more ideas into the world for
                    others to build on, creating exponential opportunities for
                    cooperation. The ideal social networks have porous edges.
                    This allows for a cross-pollination of ideas, and more
                    opportunities to &ldquo;give a little extra.&rdquo;
                  </p>
                  <p>
                    Look no further than the words you&rsquo;re reading for an
                    example. When ideas are given voice, others are invited to
                    engage. We&rsquo;re glad you&rsquo;re here.
                  </p>
                </div>
              </div>
            </div>
            <div
              className={styles.twoColumn}
              style={{ backgroundColor: "#0A2033" }}
            >
              <div>
                <div className={sharedStyles.stickyPhotoWrapper}>
                  <GridCollagePhoto
                    src={section3Image.url}
                    width={section3Image.width}
                    height={section3Image.height}
                    maxWidth={maxSectionImageWidth}
                    imgix={true}
                    cols={6}
                    rows={10}
                    altText={section3Image.altText}
                  />
                </div>
              </div>
              <div className={styles.rightColumn}>
                <h2
                  className={styles.contentHeader}
                  style={{ color: "#B2C6D6" }}
                >
                  Make cooperation observable.{" "}
                </h2>
                <p className="a-h-4 italic mb-4" style={{ color: "#B2C6D6" }}>
                  Create transparent systems.
                </p>
                <div className="user-content-athena">
                  <p>
                    Feedback loops are essential to cooperation. Humans are
                    highly observant social creatures, and as mentioned
                    previously, we tend to mimic behaviors that we view as
                    established norms. By embracing sharing and collaboration as
                    routine social practices, this ethos of openness is
                    normalized for ourselves and those around us.
                  </p>
                  <p>
                    The same principles of collaboration that inspired open
                    office spaces are being adopted in the digital realm. With
                    the massive shift to video conferencing, some organizations
                    have started recording all meetings to create a more
                    transparent work culture. A library of meetings allows
                    coworkers to observe others&rsquo; collaboration and
                    problem-solving and better understand perspectives across
                    teams.
                  </p>
                </div>
              </div>
            </div>
            <div
              className={styles.twoColumn}
              style={{ backgroundColor: "#20362E" }}
            >
              <div>
                <div className={sharedStyles.stickyPhotoWrapper}>
                  <GridCollagePhoto
                    src={section4Image.url}
                    width={section4Image.width}
                    height={section4Image.height}
                    maxWidth={maxSectionImageWidth}
                    imgix={true}
                    cols={6}
                    rows={10}
                    altText={section4Image.altText}
                  />
                </div>
              </div>
              <div className={styles.rightColumn}>
                <h2
                  className={styles.contentHeader}
                  style={{ color: "#D3EDF0" }}
                >
                  Make cooperation the default.
                </h2>
                <p className="a-h-4 italic mb-4" style={{ color: "#D3EDF0" }}>
                  Provide fewer avenues for self-interest.
                </p>
                <div className="user-content-athena">
                  <p>
                    It may seem off-putting at first, but less individual choice
                    is often better for the collective.{" "}
                    <a
                      href="https://mitsloan.mit.edu/shared/ods/documents/?DocumentID=4654"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Research
                    </a>{" "}
                    shows that when given time to choose between cooperation and
                    competition, people will begin to consider more selfish
                    options. When time is constrained, however, a greater number
                    of people intuitively choose cooperation. If cooperation is
                    positioned as the default, we&rsquo;re more likely to bypass
                    the self-interested cost-benefit analysis and do
                    what&rsquo;s best for the group.
                  </p>
                  <p>
                    UX and UI are used to create more direct avenues for
                    cooperation in nearly every corner of our digital lives.
                    Apps, sites, and social platforms collect user data to
                    create personalized experiences and optimize the service. By
                    design, users don&rsquo;t always realize their data is being
                    collected. Are there ethical hang-ups with this blurring of
                    coercion and cooperation? Absolutely. Do most of us want our
                    data being sold to advertisers? Doubtful. But there is also
                    a cooperative benefit to sharing data. If most people opted
                    out of Google Maps location tracking, we wouldn&rsquo;t know
                    to exit before the traffic jam.
                  </p>
                </div>
              </div>
            </div>
            <div
              className={styles.twoColumn}
              style={{ backgroundColor: "#382E3C" }}
            >
              <div>
                <div className={sharedStyles.stickyPhotoWrapper}>
                  <GridCollagePhoto
                    src={section5Image.url}
                    width={section5Image.width}
                    height={section5Image.height}
                    maxWidth={maxSectionImageWidth}
                    imgix={true}
                    cols={8}
                    rows={10}
                    altText={section5Image.altText}
                  />
                </div>
              </div>
              <div className={styles.rightColumn}>
                <h2
                  className={styles.contentHeader}
                  style={{ color: "#C3BFBD" }}
                >
                  Envision and demonstrate collective benefit.{" "}
                </h2>
                <p className="a-h-4 italic mb-4" style={{ color: "#C3BFBD" }}>
                  Imagine openly and wildly.
                </p>
                <div className="user-content-athena">
                  <p>
                    Most ideas never see the light of day. Social barriers and
                    fear of judgement choke out our innate desire to outwardly
                    express curiosity and imagination. Relegated to the recesses
                    of our minds, ideas are starved of the cooperation they need
                    to spark innovation. In a competition-obsessed society, we
                    hold up innovation as an individual achievement, when in
                    reality it&rsquo;s the result of many inputs coming together
                    from diverse perspectives. By sharing ideas, no matter the
                    stage of development, we begin to create a more tangible
                    vision of the future for ourselves and the those around us
                    to work towards.
                  </p>
                  <p>
                    Instead of an example of this principle, how about a little
                    nudge: Grab ahold of that idea that&rsquo;s been bouncing
                    around in your head and share it with someone. And then ask
                    them what they've been dreaming up.
                  </p>
                  <hr />
                  <p>
                    Breaking free from competition on a societal level is
                    daunting, no doubt. By focusing on the only thing we can
                    truly control, our own behavior, we can create a mindset
                    shift in our own lives with effects that extend far beyond.
                  </p>
                </div>
              </div>
            </div>

            <ContributorsGrid
              zeus={article.zeusContributors}
              other={article.otherContributorsGrid}
            />
            <MoreArticlesGrid id={article.id} issue={article.issue[0].id} />
            <AthenaCta data={athenaCta} />
          </div>
          {/* .article-body */}
        </div>
        {/* .article */}
      </article>
      <PreFooter />
    </Layout>
  )
}

export default Article

export const query = graphql`
  query($slug: String!) {
    craft {
      article: entry(slug: [$slug], section: "athenaArticles") {
        id
        title
        seomatic {
          metaTagContainer
          metaLinkContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        ... on Craft_athenaArticles_athenaArticleCustom_Entry {
          articleSubTitle
          issue {
            id
            title
          }
          coverImages {
            ... on Craft_coverImages_BlockType {
              ratio_16x9 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
              ratio_4x3 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
            }
          }
          zeusContributors {
            id
            title
            uri
            ... on Craft_people_person_Entry {
              portrait {
                url
                width
                height
                focalPoint
                ... on Craft_people_Asset {
                  altText
                }
              }
            }
          }
          otherContributors {
            ... on Craft_otherContributors_TableRow {
              name
              website
            }
          }
          otherContributorsGrid {
            ... on Craft_otherContributorsGrid_BlockType {
              contributorName
              contributorWebsite
              contributorPhoto {
                url
                width
                height
                focalPoint
                ... on Craft_athenaContent_Asset {
                  altText
                }
              }
            }
          }
          athenaCta {
            ...AthenaCta
          }
        }
      }
      headerImage: asset(id: 24110) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      section1Image: asset(id: 24111) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      section2Image: asset(id: 24112) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      section3Image: asset(id: 24113) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      section4Image: asset(id: 24114) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      section5Image: asset(id: 24115) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
    }
  }
`
